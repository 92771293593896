// @flow
import React, { memo } from "react"
import LazyLoad from "react-lazyload"
import isEqual from "lodash/isEqual"
import { RichText } from "prismic-reactjs"

import {
  htmlSerializerUpdateStyle,
  linkResolver,
} from "@lesmills/gatsby-theme-common"

type HeroInformationOffersProps = {|
  data: object,
|}

const Hero = ({ data }: HeroInformationOffersProps) => {
  const {
    hero_title = {},
    note_text = {},
    hero_photo = {
      url: "",
      alt: "",
      thumbnails: {
        mobile: {
          url: "",
          alt: "",
        },
      },
    },
    hero_video = {},
    hero_highlight = {},
  } = data || {}

  const heroVideoUrl = hero_video && hero_video.raw && hero_video.raw.url
  const heroThumbnails = hero_photo.thumbnails || {}
  const mobileHero = heroThumbnails.mobile || {}
  const ipadHero = heroThumbnails.ipad || {}
  const largeHero = heroThumbnails.large || {}
  const extraLargeHero = heroThumbnails.extra_large || {}
  const doubleExtraLargeHero = heroThumbnails.double_extra_large || {}

  return (
    <>
      <section className="relative sm:h-602 lg:h-612 h-480" data-cy="hero">
        {heroVideoUrl ? (
          <LazyLoad once>
            <video
              autoPlay="autoPlay"
              loop="loop"
              muted="muted"
              playsInline="playsInline"
              className="absolute top-0 left-0 object-cover h-full w-full"
            >
              <source src={heroVideoUrl} type="video/mp4"></source>
            </video>
          </LazyLoad>
        ) : (
          <>
            <div className="w-full h-full absolute top-0 left-0 bg-gray-overlay" />
            <LazyLoad once>
              <picture>
                <source
                  srcSet={doubleExtraLargeHero.url}
                  media="(min-width: 1920px)"
                />
                <source
                  srcSet={extraLargeHero.url}
                  media="(min-width: 1440px)"
                />
                <source srcSet={largeHero.url} media="(min-width: 1024px)" />
                <source srcSet={ipadHero.url} media="(min-width: 768px)" />
                <source srcSet={mobileHero.url} media="(min-width: 375px)" />
                <img
                  alt={hero_photo.alt}
                  src={hero_photo.url}
                  className="h-full w-full object-cover"
                  data-cy="hero-img"
                />
              </picture>
            </LazyLoad>
          </>
        )}
        <div className="absolute top-1/2 left-1/2 modal-content text-center md:w-popover w-290 mt-25 md:mt-0">
          <RichText
            render={hero_title.raw}
            htmlSerializer={htmlSerializerUpdateStyle(
              "",
              "md:tracking-normal tracking-x-2 uppercase font-primary text-white sm:text-75xxl text-35xxl sm:leading-60 leading-56 mb-8 text-center"
            )}
          />
          <RichText
            render={note_text.raw}
            linkResolver={linkResolver}
            htmlSerializer={htmlSerializerUpdateStyle(
              "",
              "mx-auto max-w-312 text-white font-avenir-roman text-base leading-24 text-center opacity-80"
            )}
          />
        </div>
      </section>
      <div className={`w-full py-16 sm:py-14 bg-gray-800`}>
        <RichText
          render={hero_highlight.raw}
          linkResolver={linkResolver}
          htmlSerializer={htmlSerializerUpdateStyle(
            "",
            "w-white-logo-lg sm:w-full mx-auto text-white uppercase text-center font-base-medium leading-20 md:leading-24 2xl:text-base text-3xs tracking-1.28"
          )}
        />
      </div>
    </>
  )
}

export default memo(Hero, (prevProps, nextProps) => {
  return isEqual(prevProps.data, nextProps.data)
})
