import React, { useState, useRef, useEffect, memo } from "react"
import isEqual from "lodash/isEqual"
import { RichText } from "prismic-reactjs"
import loadable from "@loadable/component"

import {
  htmlSerializerUpdateStyle,
  linkResolver,
  ProgressBar,
  getOfferById,
  getParamsFromURL,
  ROUTES,
  setLocalStorage,
  USER_COUNTRY,
  getProduct,
  SENTRY_ACTIONS,
  captureException,
  getSessionStorage,
  CURRENT_COUNTRY,
  getListProducts,
  Spinner,
} from "@lesmills/gatsby-theme-common"
import type { OffersPageType } from "../../types/OffersPageType"

import { navigate } from "gatsby"
import { hasParamsInURL } from "@lesmills/gatsby-theme-common/src/utils/utilities"

const Subscription = loadable(() => import("./Subscription"), {
  fallback: <Spinner />,
})

type Props = {
  prismicData?: OffersPageType,
  lang: string,
  handleRedeemNow: () => void,
  layoutData: Object,
  personalizedOfferPage?: boolean,
  setOffer: () => void,
  offer: Object,
  underRedeem: Object,
  redeemRef: Object,
}

const Redeem = ({
  prismicData,
  lang,
  handleRedeemNow,
  layoutData = {},
  personalizedOfferPage,
  setOffer,
  offer = {},
  underRedeem = {},
  redeemRef = {},
}: Props) => {
  const [loading, setLoading] = useState(0)
  const [relatedRetailProduct, setRelatedRetailProduct] = useState([])
  const offerId = getParamsFromURL("offer_id")
  const affId = getParamsFromURL("aff_id")
  const transactionId = getParamsFromURL("transaction_id")
  const {
    redeem_description = {},
    redeem_information = {},
    redeem_note = {},
    redeem_description_with_affiliate_name = {},
    hi_there = {},
    hello_member = {},
  } = prismicData || {}
  const isUnmount = useRef(false)

  const setAffiliateOffer = data => {
    const errors = data.errors

    if (errors) {
      handleError(errors)

      return
    }

    //https://lesmillsinternational.atlassian.net/browse/AB2B-905
    let res = data.data.getAffiliateOffers
    res.sort((a, b) => a.sort_order - b.sort_order)

    const offers = res.map(item => ({
      offerId,
      transactionId,
      affiliateId: affId,
      currency: item.product_price_point && item.product_price_point.currency,
      ...item,
    }))

    getProduct(setLoading, getListProducts, ({ data }) => {
      const products = data.getValidProducts && data.getValidProducts.products

      setRelatedRetailProduct(products)
    })

    setLocalStorage(USER_COUNTRY, getSessionStorage(CURRENT_COUNTRY))
    setOffer(offers)
  }

  const handleError = errors => {
    navigate(ROUTES(lang).NOT_FOUND)

    // Send error to Sentry
    if (errors.length > 0) {
      captureException({
        action: SENTRY_ACTIONS.GET_OFFER_BY_ID,
        requestVariables: {
          affId,
          offerId,
          transactionId,
        },
        ...errors[0],
      })
    }
  }

  useEffect(() => {
    if (!hasParamsInURL()) {
      // https://lesmillsinternational.atlassian.net/browse/LA-722
      // When customers try to access /offers, without the TUNE Paremeters Direct them to our Retail landing page
      navigate(ROUTES(lang).SALES_LANDING)
      return
    }

    getProduct(
      number => {
        if (!isUnmount.current) {
          setLoading(number)
        }
      },
      getOfferById,
      setAffiliateOffer,
      handleError,
      {
        offer_id: offerId,
        ...(!personalizedOfferPage && {
          affiliate_id: affId,
        }),
      }
    )

    // This is used to avoid memory leak when unmount
    return () => {
      isUnmount.current = true
    }
  }, [])

  const affiliateName =
    offer[0] && offer[0].affiliate && offer[0].affiliate.name

  const replaceRaw = fields =>
    fields.raw
      ? fields.raw.map(item => ({
          ...item,
          text:
            item.text && item.text.replace("@affiliate_name", affiliateName),
        }))
      : []

  const renderHelloMember = affiliateName
    ? replaceRaw(hello_member || {})
    : hi_there && hi_there.raw
  const renderRedeemDes = affiliateName
    ? replaceRaw(redeem_description_with_affiliate_name || {})
    : redeem_description && redeem_description.raw

  const renderOffer = () => {
    if (loading < 100)
      return (
        <div className="mx-auto my-20 md:w-progress-bar w-full">
          <ProgressBar
            currentValue={loading}
            classNames={{ wrapper: " w-full" }}
          />
        </div>
      )

    return (
      <div className={"flex flex-wrap justify-center"}>
        {/* https://lesmillsinternational.atlassian.net/browse/AB2B-905 */}
        {offer.map((item, index) => {
          return (
            <Subscription
              key={item.product_handle}
              handleRedeemNow={() => handleRedeemNow(item)}
              offer={item}
              underRedeem={index === offer.length - 1 ? underRedeem : null}
              prismicData={prismicData}
              layoutData={layoutData}
              retailProducts={relatedRetailProduct}
            />
          )
        })}
      </div>
    )
  }

  return (
    <>
      <section className="md:py-96 py-64">
        <div className="max-w-725 mx-auto px-20 md:px-0">
          <RichText
            render={renderHelloMember}
            htmlSerializer={htmlSerializerUpdateStyle(
              "",
              "uppercase sm:font-base-heavy uppercase text-center sm:leading-20 sm:text-3xs text-gray-800 leading-34 text-center font-primary text-6xl sm:mt-18 mb-22 mt-0",
              "",
              "",
              "",
              undefined,
              undefined,
              "",
              "",
              "",
              "",
              "text-gray-800 font-primary text-6xl leading-33 text-center text-gray-800 uppercase mb-16"
            )}
          />
          <RichText
            render={renderRedeemDes}
            linkResolver={linkResolver}
            htmlSerializer={htmlSerializerUpdateStyle(
              "",
              "font-avenir-roman opacity-70 text-base leading-28 text-center text-gray-800"
            )}
          />
        </div>
      </section>

      <div
        ref={redeemRef}
        className="md:pt-80 md:pb-23 py-45 flex flex-col items-center justify-center md:py-96 py-70 bg-gray-30"
      >
        <RichText
          render={redeem_information.raw}
          linkResolver={linkResolver}
          htmlSerializer={htmlSerializerUpdateStyle(
            "",
            "font-primary text-6xl leading-33 text-center text-gray-800 uppercase mb-25 max-w-335"
          )}
        />
        {renderOffer()}
        <div className="mt-25 md:w-btn-xl w-227">
          <RichText
            render={redeem_note.raw}
            linkResolver={linkResolver}
            htmlSerializer={htmlSerializerUpdateStyle(
              "",
              "text-xs font-avenir-roman opacity-80 text-center leading-18 text-gray-800"
            )}
          />
        </div>
      </div>
    </>
  )
}

export default memo(Redeem, (prevProps, nextProps) => {
  return isEqual(prevProps.offer, nextProps.offer)
})
