// @flow
import React, { memo } from "react"
import isEqual from "lodash/isEqual"
import { RichText } from "prismic-reactjs"

import LazyLoad from "react-lazyload"

import {
  htmlSerializerUpdateStyle,
  linkResolver,
} from "@lesmills/gatsby-theme-common"

type Props = {|
  item: Object,
  index: number,
|}

const Challenge = ({ item, index }: Props) => {
  const {
    challenge_title = {},
    challenge_description = {},
    challenge_photo = {},
  } = item || {}

  return (
    <div
      className={`lg:max-w-896 2xl:max-w-1070 3xl:max-w-1282 mx-auto flex flex-wrap lg:py-96 items-center ${
        index % 2
          ? "lg:flex-row-reverse"
          : "lg:flex-row border-b 3xl:border-b-0 border-gray-140"
      }`}
    >
      <div className="lg:w-1/2 w-full h-297 sm:h-605 lg:h-353 2xl:h-421 3xl:h-504">
        <LazyLoad once height="h-718">
          <img
            src={challenge_photo.url}
            alt={challenge_photo.alt}
            className="object-cover w-full h-full"
          />
        </LazyLoad>
      </div>
      <div className="lg:w-1/2 w-full sm:px-0 px-20 flex lg:pt-0 lg:pb-0 pt-32 pb-64">
        <div
          className={`3xl:max-w-507 2xl:max-w-441 mx-auto lg:mx-0 lg:max-w-368 max-w-441 ${
            index % 2
              ? "sm:max-w-688"
              : "lg:ml-112 2xl:ml-129 3xl:ml-167 sm:max-w-448"
          }`}
        >
          <RichText
            render={challenge_title.raw}
            htmlSerializer={htmlSerializerUpdateStyle(
              "",
              "",
              "",
              "",
              "",
              undefined,
              undefined,
              "",
              "",
              "",
              "",
              "text-gray-800 mb-25 font-primary text-6xl leading-30 lg:leading-28 uppercase text-center lg:text-left lg:max-w-385 xl:max-w-347 3xl:max-w-507 sm:max-w-448 mx-auto lg:mx-0"
            )}
          />
          <div
            className={`${
              index % 2 ? " lg:text-left text-center" : "text-left"
            }`}
          >
            <RichText
              linkResolver={linkResolver}
              render={challenge_description.raw}
              htmlSerializer={htmlSerializerUpdateStyle(
                "",
                "font-avenir-roman text-base leading-28 text-gray-800 sm:mb-0 opacity-70",
                "list-disc list-inside",
                "font-avenir-roman text-base leading-28 text-gray-800 opacity-70"
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Challenge, (prevProps, nextProps) => {
  return isEqual(prevProps.item, nextProps.item)
})
