import React, { useState, useRef, memo } from "react"
import isEqual from "lodash/isEqual"

import {
  handleSaveProduct,
  isBrowser,
  Notification,
} from "@lesmills/gatsby-theme-common"
import Hero from "./Hero"
import {
  WorkoutVideos,
  JoinCommunity,
  Testimonials,
  Stream,
  FrequentlyAskedQuestions,
  TryTheApp,
  Footer,
} from "@lesmills/gatsby-theme-sales-landing"
import StickyToast from "./StickyToast"
import Header from "./Header"
import Challenges from "./Challenges"
import Redeem from "./Redeem"

type Props = {|
  data: Object,
  layoutData: Object,
  user: object,
  lang: string,
|}

const RefinedOffer = ({ data, layoutData, user, lang }: Props) => {
  const [offer, setOffer] = useState([])
  const underHeader = useRef({})
  const underRedeem = useRef({})
  const [updateUserError, setUpdateUserError] = useState()
  const redeemRef = useRef(null)

  const {
    embedded_video_title = {},
    embedded_video_subtitle = {},
    join_community_title = {},
    join_community_subtitle = {},
    stream_title = {},
    stream_subtitle = {},
    stream_photo,
    faqs_title = {},
    redeem_button = {},
    try_the_app_title,
    try_the_app_subtitle,
    cancel_free_trial_note,
    note_text = {},
    body1 = [],
    copyright = {},
  } = data || {}

  const { les_mills_plus_logo = {} } = layoutData || {}

  const embed_video = data.body3 || []
  const join_community = data.body4 || []
  const testimonials = data.body5 || []
  const stream = data.body7 || []
  const faqs = data.body6 || []
  const challenges = data.body8 || []
  const isShowTryTheApp =
    try_the_app_title &&
    try_the_app_subtitle &&
    redeem_button &&
    cancel_free_trial_note

  const [footer_navigations, footer_socials] = body1
  const footer = {
    navigation: footer_navigations,
    social: footer_socials,
  }

  const handleRedeemNow = item => {
    handleSaveProduct(
      user,
      item,
      () => {},
      lang,
      true,
      false,
      error => setUpdateUserError(layoutData[error.errorType].text)
    )
  }

  const handleClickStartFreeTrial = () => {
    //https://lesmillsinternational.atlassian.net/browse/AB2B-918
    // if there is only one offer
    // navigate to step 1
    if (offer.length === 1) {
      handleRedeemNow(offer[0])

      return
    }

    // if there are more than one offer
    // scroll user to top
    redeemRef.current &&
      redeemRef.current.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <>
      {Object.keys(offer).length > 0 && (
        <StickyToast
          redeem_button={redeem_button}
          note_text={note_text}
          handleOnClick={handleClickStartFreeTrial}
          underRedeem={underRedeem}
        />
      )}
      <Header
        affiliateLogo={
          offer[0] && offer[0].affiliate && offer[0].affiliate.logo_url
        }
        underHeader={underHeader}
        headerLogo={les_mills_plus_logo}
      />
      <div className="absolute md:top-0 top-122" ref={underHeader} />
      <Hero data={data} />
      {updateUserError ? (
        <Notification
          type="error"
          message={updateUserError}
          classNames={{
            wrapper: "w-80/100 justify-center mx-auto mt-30 ",
          }}
        />
      ) : null}
      <Redeem
        prismicData={data}
        layoutData={layoutData}
        handleRedeemNow={handleRedeemNow}
        lang={lang}
        setOffer={setOffer}
        offer={offer}
        underRedeem={underRedeem}
        redeemRef={redeemRef}
      />
      {embed_video.length > 0 && (
        <WorkoutVideos
          className="py-64 sm:py-96"
          data={embed_video}
          title={embedded_video_title}
          subtitle={embedded_video_subtitle}
          subtitleClassName=" opacity-70 text-gray-800 font-avenir-roman"
          titleClassName="max-w-347 sm:max-w-9/10"
        />
      )}
      {challenges.length > 0 && <Challenges challenges={challenges[0].items} />}
      {join_community.length > 0 && (
        <JoinCommunity
          data={join_community}
          title={join_community_title}
          subtitle={join_community_subtitle}
          className="max-w-1280 mx-auto sm:pt-96 sm:pb-75 pt-64 pb-45"
          subtitleClassName=" opacity-70 text-gray-800 font-avenir-roman"
        />
      )}
      {testimonials.length > 0 && (
        <Testimonials
          data={testimonials}
          starClassName="text-orange-400"
          wrapperClassName="flex flex-wrap mt-32 lg:justify-between justify-center mx-auto max-w-335 md:max-w-688 lg:max-w-928 2xl:max-w-1107"
          className="py-64 py-96"
          itemClassName="py-48 2xl:px-24 lg:px-21 sm:px-31 px-27 mb-24 lg:mb-0 shadow lg:w-1/3 lg:max-w-288 2xl:max-w-346 w-full text-white bg-gray-800"
        />
      )}
      {stream.length > 0 && (
        <Stream
          data={stream}
          title={stream_title}
          subtitle={stream_subtitle}
          photo={stream_photo}
          classNames={{
            platformName:
              "sm:text-3xs text-xs font-base-medium sm:leading-24 leading-16 mt-18 text-gray-800 max-w-70/100 md:max-w-49/100 lg:max-w-full flex justify-center",
            subtitle: " opacity-70 font-avenir-roman text-gray-800",
            deviceIcons: "sm:max-w-60/100 md:mt-45 sm:mb-70 mb-50",
            wrapper: "pt-64 sm:pt-96",
          }}
        />
      )}
      {faqs.length > 0 && (
        <section id="faqs">
          <FrequentlyAskedQuestions
            data={faqs}
            title={faqs_title}
            classNames={{}}
            opened={
              isBrowser && window && window.innerWidth > 600 ? true : false
            }
          />
        </section>
      )}
      {isShowTryTheApp && (
        <TryTheApp
          data={data}
          isOfferPage
          classNames={{
            content: "lg:max-w-400 sm:max-w-470 max-w-312",
            title: "lg:w-252",
            textNote:
              " opacity-70 sm:w-252 text-xs font-avenir-roman text-gray-800",
            subtitle: " opacity-80 lg:w-btn-xl font-avenir-roman text-gray-800",
            image: " lg:w-252",
            wrapper: "sm:my-96 my-58",
          }}
          handleRedeemNow={handleClickStartFreeTrial}
        />
      )}
      <Footer links={footer} copyright={copyright.text} offersPage />
    </>
  )
}

export default memo(RefinedOffer, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.data, nextProps.data) &&
    isEqual(prevProps.user, nextProps.user) &&
    isEqual(prevProps.layoutData, nextProps.layoutData)
  )
})
